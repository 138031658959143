import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCommentApprovalComponent } from '../dialog-comment-approval/dialog-comment-approval.component';
import { SupplierListAdmin } from 'src/app/interfaces/supplierListAdmin.interface';
import { MessageBannerServiceService } from '../services/message-banner-service.service';

@Component({
  selector: 'app-dialog-details-supplier-admin',
  templateUrl: './dialog-details-supplier-admin.component.html',
  styleUrls: ['./dialog-details-supplier-admin.component.scss']
})
export class DialogDetailsSupplerAdminComponent implements OnInit {

  miFormulario: FormGroup = this.fb.group({
    id: [''],
    supplierNumber: ['', Validators.maxLength(11)],
    name: ['', Validators.maxLength(20)],
    isVisible: [false]
  });

  constructor(public dialogo: MatDialogRef<DialogCommentApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private popUpService: MessageBannerServiceService,) { }

  ngOnInit(): void {
    this.miFormulario.get('id')?.setValue(this.data.supplierInfo?.id, { emitEvent: false });
    this.miFormulario.get('supplierNumber')?.setValue(this.data.supplierInfo?.supplierNumber, { emitEvent: false });
    this.miFormulario.get('name')?.setValue(this.data.supplierInfo?.name, { emitEvent: false });
    this.miFormulario.get('isVisible')?.setValue(this.data.supplierInfo?.isVisible, { emitEvent: false });
  }

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    const valor = this.miFormulario.value;
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.invalid) {
      if (this.miFormulario.get('comments')?.errors) {
        this.popUpService.showMessage('ERROR', 'Type some comments to approve the inspection', true, true, false);
      }
      // return;
      this.dialogo.close({ closed: true, comments: valor });
    }
    else {
      this.dialogo.close({ closed: true, comments: valor });
    }
  }

}

interface DialogData {
  title: string,
  showCloseButton?: boolean,
  showAcceptButton?: boolean,
  supplierInfo?: SupplierListAdmin
}