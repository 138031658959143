import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo.component';

@Injectable({
  providedIn: 'root'
})
export class MessageBannerServiceService {

  constructor(private dialogo: MatDialog) { }

  showMessage(titulo: string, message: string, translate: boolean, showAccept: boolean, showClose: boolean, callback?: (resp: any) => void) {
    const config = {
      data: {
        titulo: titulo,
        mensaje: message,
        translate: translate,
        showAcceptButton: showAccept,
        showCloseButton: showClose
      }
    };

    this.dialogo.open(DialogoComponent, config).afterClosed().subscribe(resp => {
      if (callback) {
        callback(resp);
      }
    });
  }
}
