import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgressBarService } from 'src/app/progress-bar/progress-bar.service';
import { SessionData } from 'src/app/shared/localstorage/sessionData';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LANG_JSON_MESSAGE } from 'src/assets/i18n/custom-translate';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad {

  constructor(public authService: AuthService, 
              public router: Router, 
              public progressBarService: ProgressBarService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    //console.log(SessionData.isLoggedIn());
    
    if (SessionData.isLoggedIn() == undefined || SessionData.isLoggedIn() !== true) {
      let msg;
      if (SessionData.getLanguage().toString() == 'es')
        msg = LANG_JSON_MESSAGE['es']['access-not-allowed'];
      else {
        msg = LANG_JSON_MESSAGE['en']['access-not-allowed'];
      }
      //window.alert(msg);
      this.progressBarService.toggle(false);
      //this.router.navigate(['/autodetect_user'], { state: { message: msg } });
      this.router.navigate(['/login']);
      return false;
    }
    else{
      const isAdmin = SessionData.getCurrentUser().admin;
      if(isAdmin){
        return true;
      }
      else{
        this.progressBarService.toggle(false);
        this.authService.doLogout();
        return false;
      }
    }
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    console.log(SessionData.isLoggedIn());
    if (SessionData.isLoggedIn() == undefined || SessionData.isLoggedIn() !== true ) {
      let msg;
      if (SessionData.getLanguage().toString() == 'es')
        msg = LANG_JSON_MESSAGE['es']['access-not-allowed'];
      else {
        msg = LANG_JSON_MESSAGE['en']['access-not-allowed'];
      }
      //window.alert(msg);
      this.progressBarService.toggle(false);
      //this.router.navigate(['/autodetect_user'], { state: { message: msg } });
      this.router.navigate(['/login']);
      return false;
    }
    else{
      const isAdmin = SessionData.getCurrentUser().admin;
      console.log('isAdmin', isAdmin);
      
      if(isAdmin){
        return true;
      }
      else{
        this.progressBarService.toggle(false);
        this.authService.doLogout();
        return false;
      }
    }
  }
}
