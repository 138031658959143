import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogoComponent } from './dialogo/dialogo.component';
import { MaterialModule } from '../material/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogCommentApprovalComponent } from './dialog-comment-approval/dialog-comment-approval.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { InspectionTemplateComponent } from './inspection-template/inspection-template.component';
import { DialogDetailsSupplerAdminComponent } from './dialog-details-supplier-admin/dialog-details-supplier-admin.component';
import { UploadCertificationComponent } from './upload-certification/upload-certification.component';



@NgModule({
  declarations: [
    DialogoComponent,
    UploadFileComponent,
    DialogCommentApprovalComponent,
    DialogDetailsSupplerAdminComponent,
    TitleBarComponent,
    InspectionTemplateComponent,
    UploadCertificationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports:[
    DialogoComponent,
    UploadFileComponent,
    TitleBarComponent
  ]
})
export class SharedModule { }
