<div class="inspection-template">
    <div class="inspection-template_title">
        <label>Upload Certification Document</label>
    </div>
    <div class="inspection-template_form">
        <div class="inspection-template_element">
            <label>File:</label>
            <div class="file-input-element" style="    display: contents;">
                <mat-form-field appearance="fill">
                    <input matInput type="text" readonly="" [formControl]="miCampo" placeholder="placeholder">
                </mat-form-field>
                <label class="file-input">
                    <input type="file" (change)="onFileSelected($event)" accept=".xls, .xlsx" placeholder="placeholder">
                </label>

                <button class="file-button" type="button" mat-raised-button (click)="fileInput.click()"
                    *ngIf="selectedFile==null">SELECT FILE</button>
            </div>
            <input hidden (change)="onFileSelected($event)" #fileInput type="file" accept=".xls, .xlsx, .pdf ">
        </div>

        <div class="inspection-template_buttons">
            <button class="file-button" mat-flat-button color="primary" *ngIf="selectedFile"
                (click)="upload()">UPLOAD</button>
            <button mat-flat-button color="accent" (click)="cerrarDialogo()" mat-dialog-close>CANCEL</button>
            <!-- <button mat-flat-button color="primary">UPLOAD</button> -->
        </div>
    </div>
</div>