/*
Documentacopm refresh token
https://www.bezkoder.com/angular-12-refresh-token/
https://www.bezkoder.com/jwt-refresh-token-node-js/

*/
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { SessionData } from '../localstorage/sessionData';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authService: AuthService) {}

  /*intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log('Intercepted', req.url, req.body);
    
    const urlIntercepted = req.url;
    if(!urlIntercepted.toLocaleLowerCase().includes('login') &&  
       !urlIntercepted.toLocaleLowerCase().includes('createuser') && 
       !urlIntercepted.toLocaleLowerCase().includes('.json')){
      const authToken = SessionData.getToken() || '';  
      req = this.addTokenHeader(req, authToken);
    }
    return next.handle(req)
      .pipe(
        catchError((error) => {
          if(error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)){
            console.log('Error de autorizacion, se requiere hacer refresh del token');            
          }
          return throwError(error);
        })
      );
  }

  handleUnauthorized(request: HttpRequest<any>, next: HttpHandler){
    if(!this.isRefreshing){
      this.isRefreshing = true;
      const token = this.authService.refreshToken();
    }
  }*/

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const urlIntercepted = req.url;
    if(!urlIntercepted.toLocaleLowerCase().includes('login') &&  
       !urlIntercepted.toLocaleLowerCase().includes('createuser') && 
       !urlIntercepted.toLocaleLowerCase().includes('.json')){
      const authToken = SessionData.getToken() || '';  
      req = this.addTokenHeader(req, authToken);
    }

    /*if(urlIntercepted.toLocaleLowerCase().includes('refresh')){
      console.log('Request:', req);      
    }*/

    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 401 || error.status === 403 ) {
            return this.handle401Error(req, next);
        }
        else{
          console.log("Error status:", error.status);          
        }

        return throwError(error);
      })
    );

    /*const authToken = SessionData.getToken() || '';
    req = this.addTokenHeader(req, authToken);
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.toLocaleLowerCase().includes('login') &&
          !req.url.toLocaleLowerCase().includes('signup') &&
          !req.url.toLocaleLowerCase().includes('badgeId') &&
          !req.url.toLocaleLowerCase().includes('plants') &&
          (error.status === 401|| error.status === 403)
        ) {
              return this.handle401Error(req, next);

        }

        return throwError(error);
      })
    );*/
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authService.refreshToken();
      if (token)
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            SessionData.updateTokens(token.accessToken, token.refreshtoken);
            this.refreshTokenSubject.next(token.accessToken);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.authService.doLogout();
            return throwError(err);
          })
        );
      //}
    }
    this.isRefreshing = false;
    this.authService.doLogout();
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
      catchError((err) => {
        this.authService.doLogout();
        return throwError(err);
      }),

      catchError((err) => {
        this.authService.doLogout();
        return throwError(err);
      }));
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    /* for Node.js Express back-end */
    //console.log('Añadiendo token');    
    return request.clone({
      //headers: request.headers.set('Authorization', 'Bearer ' + token),
      headers: request.headers
        .set('Authorization', 'Bearer ' + token)
    });
  }
}
