<div class="inspection-template">
    <div class="inspection-template_title">
        <label>Inspection Template</label>
    </div>
    <div class="inspection-template_form">

        <div class="inspection-template_element">
            <label>Template:</label>
            <div class="file-input-element">
                <mat-form-field appearance="fill">
                    <input matInput type="text" readonly="" placeholder="{{placeholdertemplate}}">
                </mat-form-field>
                <button mat-flat-button color="primary" class="file-button" (click)="download()">DOWNLOAD</button>
            </div>
        </div>

        <div class="inspection-template_element">
            <label>File:</label>
            <div class="file-input-element">
                <mat-form-field appearance="fill">
                    <input matInput type="text" readonly="" [formControl]="miCampo">
                    <!-- <span class="file-name">{{selectedFile?.name}}</span> -->
                </mat-form-field>
                <label class="file-input">
                    <input type="file" (change)="onFileSelected($event)" accept=".xls, .xlsx">
                    <!-- <span class="file-button">SELECT FILE</span> -->
                </label>

                <button class="file-button" type="button" mat-raised-button (click)="fileInput.click()" *ngIf="selectedFile==null">SELECT FILE</button>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file" accept=".xls, .xlsx">
                <!-- <span class="file-name">{{selectedFile?.name}}</span> -->
                <button  class="file-button" mat-flat-button color="primary"  *ngIf="selectedFile" (click)="upload()">UPLOAD</button>
            </div>
        </div>

        <div class="inspection-template_buttons">
            <button mat-flat-button color="accent" (click)="cerrarDialogo()" mat-dialog-close>CANCEL</button>
            <!-- <button mat-flat-button color="primary">UPLOAD</button> -->
        </div>
    </div>
</div>