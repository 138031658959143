// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var CONFIG_APP: any;
//declare var drawGauge: any;
import './../assets/config/config.js';

export const environment = {
  production: false,
  apiUrl:CONFIG_APP.apiUrl,
  //uno:CONFIG_APP.apiUrl

  localDev: true,
  tenantId: '67416604-6509-4014-9859-45e709f53d3f',
  clientId: '7421bc7b-30e5-4660-aac6-fb9382b3c365',
  appId: 'api://df06f680-95dd-4383-a1b0-b4a02bdf6ff3/hwapi',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
