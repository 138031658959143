import { Component, HostBinding, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionData } from '../shared/localstorage/sessionData';
import { ProgressBarService } from './progress-bar.service';
declare var CONFIG_APP: any;
import './../../assets/config/config.js';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  idleState = 'Not started.';
  status: boolean = false;
  lastPing?: any = null;

  constructor(
    private progressBarService: ProgressBarService,
    public authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(CONFIG_APP.idle);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(CONFIG_APP.idleTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.resetSessionTime();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.authService.doLogout();
    });

    /*idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
    });*/

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(CONFIG_APP.keepAliveInterval);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.resetSessionTime();
  }

  resetSessionTime() {
    if (SessionData.isLoggedIn()) {
      if(!SessionData.sessionTimeIsOver()){
        this.idle.watch();
        this.idleState = 'Started.';
        SessionData.startTimeSession();
      }else{
        this.authService.doLogout();
      }
    } else {
      console.log('no ha iniciado session');
    }
  }

  ngOnInit(): void {
    this.progressBarService.obsProgressBarToggle.subscribe((status) => {
      this.status = status;
      this.resetSessionTime();
    });
    this.resetSessionTime();

  }
}
