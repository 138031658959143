import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageBannerServiceService } from '../services/message-banner-service.service';

@Component({
  selector: 'app-dialog-comment-approval',
  templateUrl: './dialog-comment-approval.component.html',
  styleUrls: ['./dialog-comment-approval.component.scss']
})
export class DialogCommentApprovalComponent implements OnInit {

  miFormulario: FormGroup = this.fb.group({
    comments: ['', [Validators.required, Validators.maxLength(500)]],
  });

  constructor(public dialogo: MatDialogRef<DialogCommentApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder,
    private popUpService: MessageBannerServiceService,) { }

  ngOnInit(): void {
  }

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(isApproved: string): void {
    const valor = this.miFormulario.get('comments')?.value;

    this.miFormulario.markAllAsTouched();
    if(this.miFormulario.invalid){
      if(this.miFormulario.get('comments')?.errors){
        this.popUpService.showMessage('ERROR', 'Type some comments to approve the inspection', true, true, false);
      }
      return;
    }
    else{
      this.dialogo.close({ closed: true, comments: valor, isApproved });
    }
  }
}

interface DialogData {
  title: string,
  showCloseButton?: boolean,
  showAcceptButton?: boolean,
}