import { Injectable } from '@angular/core';
import { User } from '../dto/user';
import { environment } from 'src/environments/environment';
import { SessionData } from '../localstorage/sessionData';
import { GeneralService } from './general.service';
import { Observable, finalize, tap, of, map, switchMap } from 'rxjs';
import { LANG_JSON_MESSAGE } from 'src/assets/i18n/custom-translate';
import { LoginResponse } from 'src/app/interfaces/loginResponse.interface';
import { catchError } from 'rxjs/operators';
import { UserInfo } from 'src/app/interfaces/userInfo.interface';
import { CreateUser, CreateUserResponse } from 'src/app/interfaces/createUser.interface';
import { HttpParams } from '@angular/common/http';
import { ChangePassResponse, GetAccessCodeResponse, PasswordChangeResponse } from 'src/app/interfaces/changePassResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends GeneralService {

  private _API_KEY: string = "utThj5ZDWRBQeFKKZhKnDThszH/OrNNDkO0B8Skgkhc8tmmQ3ZFbHayizI6npvYB6Y9kCsfedpln5gVx6reBlg==";

  loginNew(usuario: string, password: string): Observable<LoginResponse>{
    this.changeProgressBar(true);
    const urlLogin = `${environment.apiUrl}/Login`;
    const info = {
      user: usuario,
      password: password,
      token: this._API_KEY
    }

    return this.http.post<LoginResponse>(urlLogin, info)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  readUser(id: number): Observable<boolean>{
    const urlRead = `${environment.apiUrl}/ReadUser`;
    const params = new HttpParams()
      .set('RequestUserId', id);
    return this.http.get<UserInfo>(urlRead, {params: params})
      .pipe(
        map(resp => {
          localStorage.setItem('session_user', JSON.stringify(resp.userData));
          return true;
        }),
        catchError( err => {
          //console.log('ReadUserError', err);          
          return of(false);
        })
      );
  }

  registerNewUser(data: CreateUser): Observable<CreateUserResponse>{
    const urlCreate = `${environment.apiUrl}/CreateUser`;
    data.token = this._API_KEY;

    this.changeProgressBar(true);
    return this.http.post<CreateUserResponse>(urlCreate, data)
    .pipe(
      finalize(() => this.changeProgressBar(false))
    );
  }

  login(acount: String, passsword: String) {
    this.changeProgressBar(true);
    let params: any;
    params = {
      ClockNumber: acount,
      Password: passsword,
    };
    return this.http
      .post<any>(`${environment.apiUrl}/Login/ClockNumber`, params)
      .subscribe({
        next: (res) => {
          this.loginSuccess(res);
        },
        error: (e) => {
          this.loginError(e);
        },
        complete: () => {
          this.changeProgressBar(false);
        },
      });
  }

  public loginError(e: any) {
    this.changeProgressBar(false);

    if (e.status == 400) {
      if (SessionData.getLanguage().toString() == 'es') {
        alert(LANG_JSON_MESSAGE['es']['login-error']);
      } else {
        alert(LANG_JSON_MESSAGE['en']['login-error']);
      }
    } else {
      alert(GeneralService.getMessageErrorStatic(e));
    }
  }

  public loginSuccess(res: any) {
    //cuando se ontiene una respuesta exitosa se almacena el token
    if (res.user == undefined) {
      alert(this.translate.instant('text-error-services'));
    } else {
      if (res.typeResponse.typeResponseId == 1) {
        //alamacenamos el usuario
        SessionData.saveToken(res.token, res.user);
        //console.log(res.user)

        let us: User = SessionData.getSessionUser();
        //se guarda el lenguaje
        SessionData.saveLanguage(us.languageCode?.includes('es') ? 'es' : 'en');
        this.translate.setDefaultLang(SessionData.getLanguage());
        //inicimos la sesion en el front

        //debemos obtener las plantas y almacenarlas en la session
        this.findPlantsUser(us.userId).subscribe({
          next: (res) => {
            if (res.typeResponse.typeResponseId == 1) {
              //almacenamos las plantas en en local storage
              localStorage.setItem('plants', JSON.stringify(res.listPlants));
              //se redirecciona al home
              //this.router.navigate(['/admin/home/']);
              this.router.navigate(['/home']);
            }
          },
          complete: () => {
            this.changeProgressBar(false);
          },
          error: (e) => {
            this.changeProgressBar(false);
            alert(GeneralService.getMessageErrorStatic(e));
          },
        });

      } else {
        alert(this.translate.instant('text-error-services'));
      }
    }
  }

  //Todo se debe actualizar
  /*refreshToken() {
    let api = `${environment.apiUrl}/Login/RefreshToken`;
    return this.http.get(api);
  }*/

  refreshToken(){
    const usuario = SessionData.getCurrentUser();
    let api = `${environment.apiUrl}/RefreshToken`;
    const info = {
      id: usuario.id,
      refreshToken: SessionData.getRefreshToken()
    }
    //console.log(info);    
    return this.http.post(api, info);
  }

  // User profile
  findPlantsUser(id: any): Observable<any> {
    let api = `${environment.apiUrl}/Users/GetMyPlants/${id}`;
    return this.doGet(api);
  }

  doLogout() {
    if (SessionData.doLogout() == null) {
      this.router.navigate(['/login']);
    }
  }

  singUp(
    clockNumber: String,
    badgeId: String,
    plantId: 0,
    languageCode: String
  ): Observable<any> {
    let params: any = {
      ClockNumber: clockNumber,
      BadgeId: badgeId,
      PlantId: plantId,
      LanguageCode: languageCode,
    };
    let api = `${environment.apiUrl}/Login/Signup/`;
    //let api = `http://localhost:8082/api-rest/Login/signup`;

    return this.doPost(api, params);
  }

  changePassword(password: string, newPassword: string): Observable<ChangePassResponse>{
    const urlChange = `${environment.apiUrl}/PasswordChange`;
    const usuario = SessionData.getCurrentUser();
    const info = {
      id: usuario.id,
      passwor: password,
      newPassword: newPassword
    }
    this.changeProgressBar(true);
    return this.http.post<ChangePassResponse>(urlChange, info)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getAccessCode(email: string): Observable<GetAccessCodeResponse> {

    this.changeProgressBar(true);
    let apiUrl = `${environment.apiUrl}/GetAccessCode`;

    const data = {
      email,
      token: this._API_KEY
    }

    return this.http.post<GetAccessCodeResponse>(apiUrl, data)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  resetPassword(dataRecieved: any): Observable<PasswordChangeResponse> {

    this.changeProgressBar(true);
    let apiUrl = `${environment.apiUrl}/PasswordChange`;

    const data = {
      ...dataRecieved,
      token: this._API_KEY
    }

    return this.http.post<PasswordChangeResponse>(apiUrl, data)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }
}
