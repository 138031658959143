import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InspectionService } from 'src/app/services/inspection.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MessageBannerServiceService } from '../services/message-banner-service.service';
import { DownloadTemplateService } from '../services/download-template.service';

interface InspectionTemplate {
  inspectionId: string,
  filename: string,
  nuevo: boolean
}

@Component({
  selector: 'app-inspection-template',
  templateUrl: './inspection-template.component.html',
  styleUrls: ['./inspection-template.component.scss']
})
export class InspectionTemplateComponent implements OnInit {

  placeholdertemplate = '';
  miCampo: FormControl = this.fb.control('');
  file: File | null = null;
  selectedFile: any = null;


  constructor(
    public dialogo: MatDialogRef<InspectionTemplateComponent>,
    private popUpService: MessageBannerServiceService,
    @Inject(MAT_DIALOG_DATA) public data: InspectionTemplate,
    private inspectionService: InspectionService,
    private fb: FormBuilder,
    private downloadTemplateService: DownloadTemplateService
  ) { }

  ngOnInit(): void {
    this.placeholdertemplate = this.data.filename;
    this.miCampo.setValue("SELECT A FILE TO UPLOAD");
  }

  download() {
    const inspectionId = this.data.inspectionId;
    const title = this.data.inspectionId + 'Template.xlsx';
    this.downloadTemplateService.downloadExcelTemplateSupplier(inspectionId, title);
  }

  upload() {
    if (this.selectedFile === null) {
      this.popUpService.showMessage('ALERT', 'First select a File', false, true, false);
    }
    else {
      this.inspectionService.checkInspectionIsComplete(this.data.inspectionId).subscribe((valor) => {
        console.log("🚀 ~ file: upload-file.component.ts:70 ~ UploadFileComponent ~ this.inspectionService.checkInspectionIsComplete ~ valor:", valor)
        if (valor[0] === "False") {

          let fileReader = new FileReader();
          fileReader.onload = (event: any) => {
            //this.showMessage('ALERT', 'File was imported correctly', false, true, false, true);
            //Se quita data:*/*;base64, de lo leido
            let content: string = event.target.result.split("base64,")[1];
            //console.log(content);
            this.inspectionService.uploadInspectionTemplate(this.data.inspectionId, content)
              .subscribe((valorUpload) => {
                console.log("⭕️--> ~ InspectionTemplateComponent ~ .subscribe ~ valorUpload:", valorUpload)
                if (valorUpload.success) {
                  this.popUpService.showMessage('ALERT', 'File was imported correctly', false, true, false, (resp) => {
                    location.reload();
                    console.log('Diálogo cerrado:', resp);
                  });
                }
                else {
                  this.popUpService.showMessage('ERROR', 'Error while sending the file: \n' + valorUpload.message, false, true, false);
                }
              },
                (error: HttpErrorResponse) => {
                  this.popUpService.showMessage('ERROR', 'Error while sending the file: ' + error.message, false, true, false);
                });
          }
          fileReader.readAsDataURL(this.file!);
        }
        else {
          this.popUpService.showMessage('ERROR', 'This inspection has been completed.', false, true, false);
        }
      },
        (error: HttpErrorResponse) => {
          this.popUpService.showMessage('ERROR', 'This inspection has been completed', false, true, false);
        });
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] ?? null;
    console.log("⭕️--> ~ file: inspection-template.component.ts:86 ~ InspectionTemplateComponent ~ onFileSelected ~ event:", event)
    let selectedFile = (event.target as HTMLInputElement).files?.item(0);
    if (selectedFile) {
      let file: File = event.target.files[0];
      this.miCampo.setValue(file.name);
      this.file = file
    }
  }

  cerrarDialogo(): void {
    this.miCampo.setValue('');
    this.dialogo.close(false);
    this.file = null;
  }
}
