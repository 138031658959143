import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
  CanLoad,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProgressBarService } from 'src/app/progress-bar/progress-bar.service';
import { LANG_JSON_MESSAGE } from 'src/assets/i18n/custom-translate';
import { SessionData } from '../localstorage/sessionData';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(public authService: AuthService, 
              public router: Router, 
              public progressBarService: ProgressBarService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (SessionData.isLoggedIn() == undefined || SessionData.isLoggedIn() !== true ) {
      let msg;
      if (SessionData.getLanguage().toString() == 'es')
        msg = LANG_JSON_MESSAGE['es']['access-not-allowed'];
      else {
        msg = LANG_JSON_MESSAGE['en']['access-not-allowed'];
      }
      //window.alert(msg);
      this.progressBarService.toggle(false);
      //this.router.navigate(['/autodetect_user'], { state: { message: msg } });
      this.router.navigate(['/login']);
    }
    return true;
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (SessionData.isLoggedIn() == undefined || SessionData.isLoggedIn() !== true ) {
      let msg;
      if (SessionData.getLanguage().toString() == 'es')
        msg = LANG_JSON_MESSAGE['es']['access-not-allowed'];
      else {
        msg = LANG_JSON_MESSAGE['en']['access-not-allowed'];
      }
      //window.alert(msg);
      this.progressBarService.toggle(false);
      //this.router.navigate(['/autodetect_user'], { state: { message: msg } });
      this.router.navigate(['/login']);
    }
    return true;
  }
}
