<div class="dialog" height="800px" width="100%" [formGroup]="miFormulario">
    <div class="dialog_button" style="text-align: center;">
        <h1 mat-dialog-title>{{data.title}}</h1>
    </div>
    <br>
    <div class="supplier-list_general">
        <div class="supplier-list_content" [formGroup]="miFormulario">
            <mat-form-field class="supplier-list_inputs" appearance="fill" style="display: none;">
                <mat-label>ID</mat-label>
                <input matInput placeholder="ID" formControlName="id" required [value]="data.supplierInfo?.id">
            </mat-form-field>

            <mat-form-field class="supplier-list_inputs" appearance="fill">
                <mat-label>Supplier Name</mat-label>
                <input matInput placeholder="Name" formControlName="name" required [value]="data.supplierInfo?.name"
                    maxlength="20">
            </mat-form-field>

            <mat-form-field class="supplier-list_inputs" appearance="fill">
                <mat-label>Supplier Number</mat-label>
                <input matInput placeholder="Number" formControlName="supplierNumber" required
                    [value]="data.supplierInfo?.supplierNumber" type="text" pattern="\d*" maxlength="11">
            </mat-form-field>

            <div class="supplier-list_inputs" appearance="fill">
                <label>Is Visible</label><br>
                <mat-radio-group formControlName="isVisible" [value]="data.supplierInfo?.isVisible"
                    style="display: grid;">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="supplier-list_button">
                <button mat-flat-button color="accent" (click)="cerrarDialogo()">CANCEL</button>
                <button mat-flat-button color="primary" (click)="confirmado()">SAVE</button>
            </div>
        </div>
    </div>

</div>