export let LANG_JSON_MESSAGE: any= {
  "es": {
    'label-conection-error': 'Error de conexion, intente nuevamente',
    'items-per-page':'Elementos por página',
    'access-not-allowed':'¡Acceso no permitido!',
    'login-error':'Usuario o contraseña incorrectos',
    'not-register':'Usuario no registrado'

  },
  "en":{
    'label-conection-error': 'Conection error, try again',
    'items-per-page':'Items per page',
    'access-not-allowed':'Access not allowed!',
    'login-error':'Incorrect passwords',
    'not-register':'Unregistered user'
  }

};
