import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo.component';
import { InspectionService } from 'src/app/services/inspection.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

interface UploadFileData{
  tipo: string,
  id: string
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  constructor(public dialogo: MatDialogRef<UploadFileComponent>,
      @Inject(MAT_DIALOG_DATA) public data: UploadFileData,
      private inspectionService: InspectionService,
      private fb: FormBuilder,
      private dialog: MatDialog,
      private router: Router) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.tipo = this.data.tipo;
  }

  id: string = '';
  tipo: string = '';

  miCampo: FormControl = this.fb.control('');
  file: File | null = null;

  cerrarDialogo(): void {
    this.miCampo.setValue('');
    this.dialogo.close(false);
    this.file = null;
  }

  cerrarDialogoWithTrue(): void {
    this.miCampo.setValue('');
    this.dialogo.close(true);
    this.file = null;
  }
  
  onFileSelected(event: any){
    let selectedFile = (event.target as HTMLInputElement).files?.item(0);
    if(selectedFile){
      let file: File = event.target.files[0];
      this.miCampo.setValue(file.name);
      this.file = file;
    }
  }

  uploadFile() {
    //Validar si hay archivo
    if (this.file === null) {
      this.showMessage('ALERT', 'First select a File', false, true, false, false);
    }
    else {
      this.inspectionService.checkInspectionIsComplete(this.id).subscribe((valor) => {
        console.log("🚀 ~ file: upload-file.component.ts:70 ~ UploadFileComponent ~ this.inspectionService.checkInspectionIsComplete ~ valor:", valor)
        if (valor[0] === "False") {

          let fileReader = new FileReader();
          fileReader.onload = (event: any) => {
            //this.showMessage('ALERT', 'File was imported correctly', false, true, false, true);
            //Se quita data:*/*;base64, de lo leido
            let content: string = event.target.result.split("base64,")[1];
            //console.log(content);
            this.inspectionService.uploadInspectionTemplate(this.id, content)
              .subscribe((valor) => {
                if(valor.success){
                  this.showMessage('ALERT', 'File was imported correctly', false, true, false, true);
                }
                else{
                  this.showMessage('ERROR', 'Error while sending the file: \n' + valor.message, false, true, false, false);  
                }
              },
              (error: HttpErrorResponse) => {
                this.showMessage('ERROR', 'Error while sending the file: ' + error.message, false, true, false, false);
              });
          }
          fileReader.readAsDataURL(this.file!);
        }
        else {
          this.showMessage('ERROR', 'This inspection has been completed.', false, true, false, false);
        }
      },
        (error: HttpErrorResponse) => {
          this.showMessage('ERROR', 'This inspection has been completed', false, true, false, false);
        });
    }
  }

  showMessage(titulo: string, message: string, translate: boolean,
    showAccept: boolean, showClose: boolean, refresh: boolean){
      this.dialog
      .open(DialogoComponent, {
        data: {
          titulo: titulo,
          mensaje: message,
          translate: translate,
          showAcceptButton: showAccept,
          showCloseButton: showClose
        }
        })
      .afterClosed()
      .subscribe( resp=> {
        console.log('Suscribe de dialog:', resp);
        if(refresh){
          //console.log("refresh the window");
          //this.router.navigate(['/home/inspection/edit_inspection/', this.id]);
          this.cerrarDialogoWithTrue();
        }
      });
  }

}
