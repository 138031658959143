import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionService } from 'src/app/services/inspection.service';
import { DialogoComponent } from '../dialogo/dialogo.component';
import { FormBuilder, FormControl } from '@angular/forms';

interface InspectionTemplate {
  inspectionId: string,
  filename: string,
  nuevo: boolean
}

@Component({
  selector: 'app-inspection-template',
  templateUrl: './inspection-template.component.html',
  styleUrls: ['./inspection-template.component.scss']
})
export class InspectionTemplateComponent implements OnInit {

  placeholdertemplate = '';
  miCampo: FormControl = this.fb.control('');
  file: File | null = null;
  selectedFile: any = null;


  constructor(
    public dialogo: MatDialogRef<InspectionTemplateComponent>,
    private dialogoError: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: InspectionTemplate,
    private inspectionService: InspectionService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    // this.placeholdertemplate = 'dfdas.xlx';
    this.placeholdertemplate = this.data.filename;

    this.miCampo.setValue("SELECT A FILE TO UPLOAD");
  }

  download() {
    this.inspectionService.getInspectionTemplate(this.data.inspectionId).subscribe(
      (valor) => {
        if (valor instanceof Blob) {
          let url = window.URL.createObjectURL(valor);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = this.data.inspectionId + 'Template.xls';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        } else {
          (error: HttpErrorResponse) => {
            this.showMessage('ERROR', error.message, false, true, false);
          }
        }
      }
    )
  }

  upload() {
    if (this.selectedFile === null) {
      this.showMessage('ALERT', 'First select a File', false, true, false);
    }
    else {
      this.inspectionService.checkInspectionIsComplete(this.data.inspectionId).subscribe((valor) => {
        console.log("🚀 ~ file: upload-file.component.ts:70 ~ UploadFileComponent ~ this.inspectionService.checkInspectionIsComplete ~ valor:", valor)
        if (valor[0] === "False") {

          let fileReader = new FileReader();
          fileReader.onload = (event: any) => {
            //this.showMessage('ALERT', 'File was imported correctly', false, true, false, true);
            //Se quita data:*/*;base64, de lo leido
            let content: string = event.target.result.split("base64,")[1];
            //console.log(content);
            this.inspectionService.uploadInspectionTemplate(this.data.inspectionId, content)
              .subscribe((valor) => {
                if (valor.success) {
                  this.showMessage('ALERT', 'File was imported correctly', false, true, false);
                }
                else {
                  this.showMessage('ERROR', 'Error while sending the file: \n' + valor.message, false, true, false);
                }
              },
                (error: HttpErrorResponse) => {
                  this.showMessage('ERROR', 'Error while sending the file: ' + error.message, false, true, false);
                });
          }
          fileReader.readAsDataURL(this.file!);
        }
        else {
          this.showMessage('ERROR', 'This inspection has been completed.', false, true, false);
        }
      },
        (error: HttpErrorResponse) => {
          this.showMessage('ERROR', 'This inspection has been completed', false, true, false);
        });
    }
  }

  showMessage(titulo: string, message: string, translate: boolean,
    showAccept: boolean, showClose: boolean) {
    this.dialogoError
      .open(DialogoComponent, {
        data: {
          titulo: titulo,
          mensaje: message,
          translate: translate,
          showAcceptButton: showAccept,
          showCloseButton: showClose
        }
      })
      .afterClosed()
      .subscribe(resp => {
        //console.log('Suscribe de dialog:', resp);

      });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] ?? null;
    console.log("⭕️--> ~ file: inspection-template.component.ts:86 ~ InspectionTemplateComponent ~ onFileSelected ~ event:", event)
    let selectedFile = (event.target as HTMLInputElement).files?.item(0);
    if (selectedFile) {
      let file: File = event.target.files[0];
      this.miCampo.setValue(file.name);
      this.file = file
    }
  }


  cerrarDialogo(): void {
    this.miCampo.setValue('');
    this.dialogo.close(false);
    this.file = null;
  }
}
