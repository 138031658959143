<!-- Upload file -->
<div class="upload-file">
    <div class="upload-file_title">
      <label>Upload</label>
    </div>
    <div class="upload-file_form">
      <div class="upload-file_element">
        <label>File:</label>
        <div class="file-input-element">
          <mat-form-field appearance="fill">
            <input matInput type="text" readonly="" [formControl]="miCampo">
          </mat-form-field>
          <label class="file-input">
            <input type="file" (change)="onFileSelected($event)" accept=".xls, .xlsx">
            <span class="file-button">SELECT FILE</span>
          </label>
        </div>
      </div>
      <div class="upload-file_buttons">
        <button mat-flat-button color="accent" mat-dialog-close (click)="cerrarDialogo()">CANCEL</button>
        <button mat-flat-button color="primary" (click)="uploadFile()">UPLOAD</button>
      </div>
    </div>
  </div>
  