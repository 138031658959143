import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionService } from 'src/app/services/inspection.service';
import { DialogoComponent } from '../dialogo/dialogo.component';
import { FormBuilder, FormControl } from '@angular/forms';

interface InspectionTemplate {
  inspectionId: string,
  filename: string,
  nuevo: boolean
}

@Component({
  selector: 'app-upload-certification',
  templateUrl: './upload-certification.component.html',
  styleUrls: ['./upload-certification.component.scss']
})
export class UploadCertificationComponent implements OnInit {

  placeholdertemplate = '';
  miCampo: FormControl = this.fb.control('');
  file: File | null = null;
  selectedFile: any = null;


  constructor(
    public dialogo: MatDialogRef<UploadCertificationComponent>,
    private dialogoError: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: InspectionTemplate,
    private inspectionService: InspectionService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    // this.placeholdertemplate = 'dfdas.xlx';
    this.placeholdertemplate = this.data.filename;

    this.miCampo.setValue("SELECT A FILE TO UPLOAD");
  }

  download() {
    this.inspectionService.getInspectionTemplate(this.data.inspectionId).subscribe(
      (valor) => {
        if (valor instanceof Blob) {
          let url = window.URL.createObjectURL(valor);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = this.data.inspectionId + 'Template.xls';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        } else {
          (error: HttpErrorResponse) => {
            this.showMessage('ERROR', error.message, false, true, false);
          }
        }
      }
    )
  }

  upload() {
    if (this.selectedFile === null) {
      this.showMessage('ALERT', 'First select a File', false, true, false);
    }
    else {
      const formData = new FormData();
      formData.append('InspectionID', this.data.inspectionId);
      formData.append('File', this.selectedFile, this.selectedFile.name);


      this.inspectionService.uploadCerficationDocumentation(formData)
        .subscribe((valor) => {
          console.log("⭕️--> ~ UploadCertificationComponent ~ .subscribe ~ valor:", valor)
          if (valor.success) {
            this.showMessage('ALERT', 'File was imported correctly', false, true, false);
          }
          else {
            this.showMessage('ERROR', 'Error while sending the file: \n' + valor.message, false, true, false);
          }
        },
          (error: HttpErrorResponse) => {
            this.showMessage('ERROR', 'Error while sending the file: ' + error.message, false, true, false);
          });

    }
  }

  showMessage(titulo: string, message: string, translate: boolean,
    showAccept: boolean, showClose: boolean) {
    this.dialogoError
      .open(DialogoComponent, {
        data: {
          titulo: titulo,
          mensaje: message,
          translate: translate,
          showAcceptButton: showAccept,
          showCloseButton: showClose
        }
      })
      .afterClosed()
      .subscribe(resp => {
        //console.log('Suscribe de dialog:', resp);

      });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] ?? null;
    let selectedFile = (event.target as HTMLInputElement).files?.item(0);
    if (selectedFile) {
      let file: File = event.target.files[0];
      this.miCampo.setValue(file.name);
      this.file = file
    }
  }


  cerrarDialogo(): void {
    this.miCampo.setValue('');
    this.dialogo.close(false);
    this.file = null;
  }
}
