// General
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Router
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

//modificacion
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';
import { AuthInterceptor } from './shared/services/authconfig.interceptor';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';
//i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    ProgressBarComponent,
    // RegistroComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,

    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: CaseInsensitiveBaseHrefFactory,
      deps: [PlatformLocation],
    },
  ],
  bootstrap: [AppComponent],
  exports: [ProgressBarComponent],
})
export class AppModule {}

//i18n
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function CaseInsensitiveBaseHrefFactory(
  platformLocation: PlatformLocation
): string {
  const path = platformLocation.pathname;
  const baseHrefFromDOM = platformLocation.getBaseHrefFromDOM(); // Get baseHref from Dom and remove start and end backslash.
  const baseHref = baseHrefFromDOM.replace(/(^\/*|\/*$)/g, ''); // Case-insensitive match baseHref in the path ignoring start backslash.
  const caseInSensitiveBaseHrefRegexp = new RegExp(`^\/*${baseHref}`, 'i');
  const matchedBaseHref = path.match(caseInSensitiveBaseHrefRegexp);

  if (matchedBaseHref) {
    const remainingPath = path.slice(matchedBaseHref[0].length);
    // Deal with case which contains basePath but should not use matchedBaseHref. Eg: base = 'base', path='BaseBall'.
    // matchedBaseHref is the end of the path or follows with white space or a new segment which starts with backslash.
    const isValidMatch = !remainingPath || !!remainingPath.match(/^[\/\s]/);
    return isValidMatch ? matchedBaseHref[0] : baseHrefFromDOM;
  }
  return baseHrefFromDOM;
}
