import { Component } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'suppliers-portal';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: any = null;

  //i18n
  constructor(
    public translate: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('en');

  }


  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
