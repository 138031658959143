var CONFIG_APP={
  //dev-it-and-apps
  //"apiUrl":'http://64.37.63.106:83/qualityportalapi/api',
  //"apiUrl":'http://localhost:3000',
  //dev
  "apiUrl": "/api",
  //test
  // "apiUrl": "https:///ws001892/APISQIP/api",

  "idleTimeout":3600,// sets an idle timeout
  "idle":5,  // after 3605 seconds of inactivity, the user will be considered timed out.
  "keepAliveInterval":15


}
