import { Injectable } from '@angular/core';
import { GeneralService } from '../shared/services/general.service';
import { Observable, finalize, tap, of, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LotGroupResponse } from '../interfaces/lotGroupResponse.interface';
import { SessionData } from '../shared/localstorage/sessionData';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HeaderResponse } from '../interfaces/headerResponse.interface';
import { switchMap } from 'rxjs/operators';
import { Caracteristica } from '../interfaces/caracteristica.interface';
import { Value } from '../interfaces/value.interface';
import { AddInspectionValueResponse } from '../interfaces/AddInspectionValueResponse.interface';
import { DashboardResponse, Inspection } from '../interfaces/dashboardResponse.interface';
import { Inspeccion } from '../interfaces/inspeccion.interface';
import { SimpleResponse } from '../interfaces/simpleResponse.interface';
import { UpdateNewFalse } from '../interfaces/updateNewFalse.interface';
import { UploadResponse } from '../interfaces/uploadResponse.interface';
import { InspectionIsComplete } from '../interfaces/inspectionIsComplete.interface';
import { ApproveInspectionBody } from '../interfaces/approveInspectionID.interface';
import { MatTableDataSource } from '@angular/material/table';
import { CertificationDocumentation } from '../interfaces/CertificationDocument.interface';
import { CertificationDocumentationHistory } from '../interfaces/CertificationDocumentHistory.interface';
import { InspectionPlans, InspectionPlansResult } from '../interfaces/InspectionPlans.interface';

interface HeaderRequest{
  //lot: string,
  group: string,
  materialNumber: string
}

interface AddHeaderResponse{
  operationFlag: boolean,
  idHeader:      string,
  error:         string
}

interface AddCharacteristicResponse{
  operationFlag: boolean;
  iDs:           Array<string[]>;
  error:         string;
}

export interface QueryParamsInspectionPlans {
  Plant?:          string;
  SupplierNumber?: string;
  MaterialNumber?: string;
  MaterialName?:   string;
  PlanId?:         string;
  Page?:           number;
}

interface InspectionCompletedResponse{
  completed:    boolean;
  error:        string;
}

@Injectable({
  providedIn: 'root'
})
export class InspectionService extends GeneralService {

  private _headerResponse!: HeaderResponse;
  error: string = '';
  idHeader: string = '';
  caracteristicas: Caracteristica [] = [];
  characteristicsToSend: Caracteristica[] = [];
  private _material: string = '';
  private _inspLotQty: number = 0;

  private _certificationDocumentation: CertificationDocumentation | undefined;
  private _certificationDocumentationHistory: CertificationDocumentationHistory[] = [];

  characteristicSelected: Caracteristica | undefined;
  idsCharacteistics!: Array<string[]>;

  requestedInspections: Inspection[] = [];
  private requestedInspectionsListSubject = new Subject<Inspection[]>();
  requestedInspectionsListObservable      = this.requestedInspectionsListSubject.asObservable();

  pendingInspections: Inspection[] = [];
  private pendingInspectionsListSubject   = new Subject<Inspection[]>();
  pendingInspectionsListObservable        = this.pendingInspectionsListSubject.asObservable();

  completedInspections: Inspection[] = [];
  private completedInspectionsListSubject   = new Subject<Inspection[]>();
  completedInspectionsListObservable        = this.completedInspectionsListSubject.asObservable();

  allInspections: Inspection[] = [];
  private allInspectionsListSubject   = new Subject<Inspection[]>();
  allInspectionsListObservable        = this.allInspectionsListSubject.asObservable();


  private dataSourceSubject = new BehaviorSubject<MatTableDataSource<Inspection>>(new MatTableDataSource(this.allInspections));
  dataSource$ = this.dataSourceSubject.asObservable();

  setDataSource(data: any[]): void {
    const dataSource = new MatTableDataSource(data);
    this.dataSourceSubject.next(dataSource);
  }

  getDataSource(): MatTableDataSource<any> {
    return this.dataSourceSubject.getValue();
  }

  //Objetos para edicion de inspection
  private _lotNumber: string = '';
  private _groupId: string = '';
  private _recordComplete: boolean = false;
  private _shipmentInfo: string = '';
  private _supplierComment: string = '';

  //Sirve para almacenar las caracteristicas cuando se visualiza una nueva inspeccion
  characteristicsNewInsp: Caracteristica[] = []; 

  setCharacteristicsNewInsp(caracteristicas: Caracteristica[]){
    this.characteristicsNewInsp = caracteristicas;
  }


  get headerResponse(){
    return this._headerResponse;
  }

  get certificationDocumentation() {
    return this._certificationDocumentation;
  }

  get certificationDocumentationHistory() {
    return this._certificationDocumentationHistory;
  }

  get characteristics(){
    return this.caracteristicas;
  }

  get material(){
    return this._material;
  }

  get inspLotQty(){
    return this._inspLotQty;
  }

  get caracteristicaActual(){
    return this.characteristicSelected;
  }

  get lotNumber(){
    return this._lotNumber;
  }

  get groupId(){
    return this._groupId;
  }
  get shipmentInfo(){
    return this._shipmentInfo;
  }
  get supplierComment(){
    return this._supplierComment;
  }


  get recordComplete(){
    return this._recordComplete;
  }

  setRecordComplete(value: boolean){
    this._recordComplete = value;
  }

  reset(){
    this.error = '';
    this.idHeader = '';
    this.caracteristicas = [];
    this.characteristicsToSend = [];
    this._material = '';
    this._inspLotQty = 0;
    this.characteristicSelected = undefined;
  }

  setCaracteristicSelected(caracteristica: Caracteristica | undefined) {
    if (caracteristica === undefined) {
      //desmarcar todo
      this.characteristicSelected = caracteristica;
      this.characteristics.forEach((val) => val.selected = '');
    }
    else {
      this.characteristicSelected = caracteristica;
      this.characteristicSelected.selected = 'selected';

      const other = this.caracteristicas.filter((value) => { return value.id !== caracteristica.id || value.mstrInspecChar !== caracteristica.mstrInspecChar });
      other.forEach((val) => {
        val.selected = '';
      });
    }
  }

  /*characteristicSavedSuccessfully(caracteristica: Caracteristica){
    //desmarcar todo
    this.characteristicSelected = undefined;
    const char = this.caracteristicas.filter((value) => {return value.operation === caracteristica.operation});
    char[0].inspected = char[0].inspect;
    char[0].status = 'Complete';

    //console.log('Caracteristicas despues de guardar: ', this.caracteristicas);    
  }*/

  characteristicSavedSuccessfully(caracteristica: Caracteristica): number {
    //desmarcar todo
    this.characteristicSelected = undefined;
    const char = this.caracteristicas.filter((value) => { return value.id === caracteristica.id });
    char[0].inspected = char[0].inspect;
    char[0].status = 'Complete';

    //Obtener el numero de caracteristicas Pending
    const charsPending = this.caracteristicas.filter((value) => {return value.status === 'Pending'});
    return charsPending.length;
  }

  getAllInspections(): Observable<DashboardResponse>{
    const urlDashboard = `${environment.apiUrl}/Dashboard`;
    const currentUser = SessionData.getCurrentUser();
    const supplier = currentUser.sapVendor;
    const supplierId = currentUser.id;
    this.changeProgressBar(true);

    const params = {
      "SupplierNumber": supplier,
      "id": supplierId
    }


    return this.http.post<DashboardResponse>(urlDashboard, params)
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }

  insertCommentInspection(inspectionID: string, comments: string, shipmentInfo: string) {
    const url = `${environment.apiUrl}/UpdateInspection/${inspectionID}`;
    this.changeProgressBar(true);

    const params = {
      "shipmentInfo": shipmentInfo,
      "supplierComment": comments
    }
    console.log("⭕️--> ~ file: inspection.service.ts:220 ~ InspectionService ~ insertCommentInspection ~ params:", params)

    return this.http.put<any>(url, params)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  setRequestedInspections(list: Inspection[]) {
    this.requestedInspections = list;
    this.requestedInspectionsListSubject.next(list);
  }

  getRequestedInspections() {
    const dataSource = new MatTableDataSource(this.requestedInspections);
    this.dataSourceSubject.next(dataSource);
  }

  setPendingInspections(list: Inspection[]) {
    this.pendingInspections = list;
    this.pendingInspectionsListSubject.next(list);
  }

  getPendingInspections() {
    const dataSource = new MatTableDataSource(this.pendingInspections);
    this.dataSourceSubject.next(dataSource);
  }

  setCompletedInspections(list: Inspection[]) {
    this.completedInspections = list;
    this.completedInspectionsListSubject.next(list);
  }

  getCompletedInspections() {
    const dataSource = new MatTableDataSource(this.completedInspections);
    this.dataSourceSubject.next(dataSource);
  }
  
  setAllInspections(list: Inspection[]) {
    this.allInspections = list;
    this.allInspectionsListSubject.next(list);
  }
  
  getLotNumbers(supplierNumber?: string): Observable<LotGroupResponse>{
    const urlLotNumbers = `${environment.apiUrl}/GetLotNumbers`;
    const supplier = supplierNumber ? supplierNumber: SessionData.getCurrentUser().sapVendor;
    this.changeProgressBar(true);
    this.reset();

    const params= new HttpParams()
      .set('SupplierNumber', supplier);

    return this.http.get<LotGroupResponse>(urlLotNumbers, {params})
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }

  crearLote(groupId: string, materialNumber: string): Observable<SimpleResponse>{
      this.reset();
      const urlGetHeaderOps = `${environment.apiUrl}/GetHeaderCharacOps`;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.changeProgressBar(true);
    
      const body: HeaderRequest = {
          group : groupId,
          materialNumber: materialNumber
      };
      console.log(`⭕️--> ${environment.apiUrl}/GetHeaderCharacOps`, urlGetHeaderOps, body, {headers})

      return this.http.post<HeaderResponse>(urlGetHeaderOps, body, {headers})
        .pipe(
          tap( resp => {
            this._headerResponse = resp;
          }),
          switchMap( respuesta => {
            console.log("⭕️--> ~ InspectionService ~ crearLote ~ respuesta:", respuesta)
            if(respuesta.erros !== null){
                //Mostrar mensaje de error
                this.error = respuesta.erros[0][0];
                return of('ERROR');            
            }
            else{
              //Continuar con el post de header, solo si se tienen caracteristicas?
              if(respuesta.characteristics.length === 0){
                  return of('SIN');              
              }
              else{
                  return this.addHeader();
              }                        
            }
        }),
        switchMap( respuestaHeader => {
          
          console.log("⭕️--> ~ InspectionService ~ crearLote ~ respuestaHeader:", respuestaHeader)
            if(respuestaHeader === 'ERROR'){
                //Hubo error al ejecutar el add Header
                this.error = 'Error while getting information: ' + this.error + "\nTry again";
                return of(false);
            }
            else{
                if(respuestaHeader === "SIN"){
                    this.error = 'Inspection does not have any Characteristic';
                    return of(false);
                }
                else{
                    const headerResponse = respuestaHeader as AddHeaderResponse;
                    if(!headerResponse.operationFlag){
                      this.error = 'Error while inserting header: ' + headerResponse.error + "\nTry again";
                      return of(false);
                    }
                    else{
                        this.idHeader = headerResponse.idHeader;
                        //Insertar caracteristicas
                        return this.addCharacteristics();
                    }
                }
            
            }
        }),
        switchMap( respuestaCaracteristicas => {
          console.log("⭕️--> ~ InspectionService ~ crearLote ~ respuestaCaracteristicas:", respuestaCaracteristicas)
  
            if(respuestaCaracteristicas === false){
                //return of(this.error);
                const simple: SimpleResponse = {msg: this.error};
                return of(simple);

            }
            else{
                const charResponse = respuestaCaracteristicas as AddCharacteristicResponse;
                this.idsCharacteistics = charResponse.iDs;
                if(!charResponse.operationFlag){
                    this.error = 'Error while sending characteristics: ' + charResponse.error + "\nTry again";
                    //return of(this.error);
                    const simple: SimpleResponse = {msg: this.error};
                    return of(simple);
                }
                else{
                    //Asignar el ID a cada caracteristica, 
                    //como el ID es el mismo que el del Header, ya no se hace ninguna relacion
                    this._inspLotQty = this._headerResponse.header.inspLotQty;
                    this._material = this._headerResponse.header.materialNumber.concat('-').concat(this._headerResponse.header.materialName);
                    this.caracteristicas = this.characteristicsToSend;
                    const simple: SimpleResponse = { id: this.idHeader, msg: 'OK' };
                    const posicionesEncontradas: { [key: string]: boolean } = {};
                    
                    this.caracteristicas.forEach(oneCharacteristic => {
                      const matchingB = this.idsCharacteistics.find(
                        oneIDInfo => {
                          return oneCharacteristic.mstrInspecChar.trim() == oneIDInfo[1].trim() && oneCharacteristic.inspectionId == oneIDInfo[0]
                        }
                      );
                      
                      if (matchingB && !posicionesEncontradas[matchingB[2]]) { // Verificar si la posición no ha sido encontrada antes
                        oneCharacteristic.id = matchingB[2];
                        posicionesEncontradas[matchingB[2]] = true; // Marcar la posición como encontrada
                      }
                      else if (matchingB && posicionesEncontradas[matchingB[2]]) { 
                        oneCharacteristic.id = String(Number(matchingB[2]) + 1);
                        posicionesEncontradas[String(Number(matchingB[2]) + 1)] = true; // Marcar la posición como encontrada
                      }
                    });
                    
                    return of(simple);
                    //return of('OK');
                } 
            }
        }),
        finalize( () => this.changeProgressBar(false))
      );

  }  

  addHeader(){
    const urlAddHeader = `${environment.apiUrl}/AddHeader`;
    const header = {
      idUser: SessionData.getCurrentUser().id,
      groupID: this._headerResponse.header.group,
      plant: this._headerResponse.header.plant,
      material: this._headerResponse.header.materialNumber,
      lotNumber: this._headerResponse.header.inspectionLot,
      sapVendor: SessionData.getCurrentUser().sapVendor,
      lotQuanty: this._headerResponse.header.inspLotQty,
      status: "Pending",
      batch: this._headerResponse.header.batch
    };

    return this.http.post<AddHeaderResponse>(urlAddHeader, header);
  }

  addCharacteristics(){
    const urlAddCharacteristic = `${environment.apiUrl}/AddCharacteristic`;
    let characteristics = [... this.headerResponse.characteristics];

    characteristics.forEach( (caracteristica) =>{
      const nuevaCaracteristica: Caracteristica = {
        mstrInspecChar: caracteristica.masterInspectionCharacteristics,
        charId:         caracteristica.operation,
        inspectionId:   this.idHeader,
        operation:      caracteristica.operation,
        characteristic: caracteristica.characteristic,
        charType:       caracteristica.procedureNumber,
        shortText:      caracteristica.characteristic,
        specifications: caracteristica.lowerValue.toString().concat('-').concat(caracteristica.upperValue.toString()),
        inspect:        caracteristica.qtyToInspec,
        inspected:      0,
        result:         0,
        lowerlimit:     caracteristica.lowerValue,
        upperlimit:     caracteristica.upperValue,
        targetvalue:    0,
        status:         'Pending',
        numberOutOfRank: 0
      }
      this.characteristicsToSend.push(nuevaCaracteristica);
    });

    //console.log('Body Add Characteristic', this.characteristicsToSend);
    return this.http.post<AddCharacteristicResponse>(urlAddCharacteristic, this.characteristicsToSend);
  }

  relacionarIDCaracteristica(ids: Array<string[]>): string{
    let msg = '';
    try{
      for(let i=0; i< ids.length; i++){
        //console.log('Searching for: ', ids[i][1]);
        const found = this.characteristicsToSend.find( element => element.operation === ids[i][1]);
        if(found !== undefined){
          found.charId = ids[i][0];
        }
      }
      msg = 'OK';
      //console.log('Characteristics to Send after: ', this.characteristicsToSend);
    }catch(error){
      //console.log(error);
      msg = 'Error al asignar ids a caracteristicas';
    }
    
    return msg;
  }

  addInspectionValue(values: Value[]): Observable<AddInspectionValueResponse>{
    const urlAddInspectionValue = `${environment.apiUrl}/AddInspectionValue`;
    console.log(`⭕️--> ${environment.apiUrl}/AddInspectionValue:`, values)
    this.changeProgressBar(true);
    return this.http.post<AddInspectionValueResponse>(urlAddInspectionValue, values)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getInspection(inspectionId: string) {
    const urlGetInspectionValues = `${environment.apiUrl}/GetInspectionValues`;
    this.reset();
    this.changeProgressBar(true);

    const params = new HttpParams()
      .set('InspectionID', inspectionId);

    return this.http.get<Inspeccion>(urlGetInspectionValues, { params })
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getInspectionPlansFromQuery(queryParams: QueryParamsInspectionPlans): Observable<InspectionPlansResult> {
    console.log("⭕️--> ~ InspectionService ~ getInspectionPlansFromQuery ~ queryParams:", queryParams)
    const endpointURL = `${environment.apiUrl}/InspectionPlans`;

    this.changeProgressBar(true);

    let params = new HttpParams()
    // .set('SupplierNumber', supplier);
    if (queryParams.Plant) {
      params = params.set('Plant', queryParams.Plant);
    }
    if (queryParams.SupplierNumber) {
      params = params.set('SupplierNumber', queryParams.SupplierNumber);
    }
    if (queryParams.MaterialNumber) {
      params = params.set('MaterialNumber', queryParams.MaterialNumber);
    }
    if (queryParams.MaterialName) {
      params = params.set('MaterialName', queryParams.MaterialName);
    }
    if (queryParams.PlanId) {
      params = params.set('PlanId', queryParams.PlanId);
    }
    if (queryParams.Page) {
      params = params.set('Page', queryParams.Page);
    }

    console.log("⭕️--> ~ InspectionService ~ getInspectionPlansFromQuery ~ params:", params)

    return this.http.get<InspectionPlansResult>(endpointURL, { params })
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getInspectionPlanFromInspectionID(inspectionID: string, supplierNumber: string): Observable<InspectionPlans> {
    const endpointURL = `${environment.apiUrl}/InspectionPlans/${inspectionID}?supplierNumber=${supplierNumber}`;

    this.changeProgressBar(true);
    
    return this.http.get<InspectionPlans>(endpointURL)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getInspectionValues(inspectionId: string): Observable<string>{
    const urlGetInspectionValues = `${environment.apiUrl}/GetInspectionValues`;
    this.reset();
    this.changeProgressBar(true);

    const params = new HttpParams()
      .set('InspectionID', inspectionId);

    return this.http.get<Inspeccion>(urlGetInspectionValues, {params})
      .pipe(
        finalize( () => this.changeProgressBar(false)),
        switchMap( (respuesta)  => {
          console.log("⭕️--> ~ InspectionService ~ switchMap ~ respuesta:", respuesta)
          if(respuesta.error !== null && respuesta.error !== ''){
            this.error = 'Error while getting information: ' + respuesta.error + "\nTry again";
            return of(this.error);
          }
          else{
            this.caracteristicas = respuesta.characteristic;
            //Asignar a cada caracteristica las propiedades que no vienen pero que se utilizan
            this.caracteristicas.forEach( caracteristica => {
              caracteristica.characteristic = caracteristica.shortText;
              caracteristica.operation = caracteristica.charId;
              if(caracteristica.inspect === caracteristica.inspected){
                caracteristica.status = 'Complete';
              }
              else{
                caracteristica.status = 'Pending';
              }
            });
            this._inspLotQty = respuesta.header.lotQuanty;
            this._certificationDocumentation = respuesta.certificationDocument;
            this._certificationDocumentationHistory = this.parseDocumentHistory(respuesta.certificationDocumentHistory);
            this._material = respuesta.header.material.concat('-').concat(respuesta.header.materialName) ;
            this._lotNumber = respuesta.header.lotNumber;
            this._groupId = respuesta.header.groupId;
            this._recordComplete = respuesta.header.recordComplete;
            this._shipmentInfo = respuesta.header.shipmentInfo || '';
            this._supplierComment = respuesta.header.supplierComment || '';
            //console.log(this.caracteristicas);

            this.caracteristicas.forEach(detailsChar => {
              if (detailsChar.inspect != detailsChar.inspected) {
                detailsChar.colorRecord = '#ecd3da';
                let test = 0;
                respuesta.inspValue.forEach(oneValue => {
                  if (String(oneValue.value) == String(detailsChar.id)) {
                    if (oneValue.status === "NOT OK") {
                      test += 1;
                    }
                  }
                })
                detailsChar.label = `${detailsChar.inspected} / ${detailsChar.inspect} inspected`;
              } else {
                let test = 0;
                respuesta.inspValue.forEach(oneValue => {
                  if (oneValue.valueIndex == detailsChar.id) {
                    if (oneValue.outOfRank) {
                      const error = '"error"';
                      test += 1;
                    }
                  }
                })
                detailsChar.label = `${detailsChar.inspected} / ${detailsChar.inspect} inspected`;
                detailsChar.colorRecord = '#e5f4e9';
                detailsChar.numberOutOfRank = test;
              }
            });
            
            return of('OK');
          }
        })
      );
  }

  parseDocumentHistory(certificationDocumentHistory: CertificationDocumentationHistory[]){
    const response: CertificationDocumentationHistory[] = [];
    certificationDocumentHistory.forEach(oneCertification => {
      oneCertification.actionDate = this.formatDateTimeForDocumentHistory(oneCertification.actionDate)
      response.push(oneCertification)
    });
    return response;
  }

  formatDateTimeForDocumentHistory(input: string | Date): string {
    let date: Date;

    if (typeof input === 'string') {
      date = new Date(input);
    } else {
      date = input;
    }

    const year    = date.getFullYear();
    const month   = String(date.getMonth() + 1).padStart(2, '0');
    const day     = String(date.getDate()).padStart(2, '0');
    const hours   = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  setColorInspection() {

   
  }

  inspectionCompleted(inspectionId: string): Observable<InspectionCompletedResponse>{
    const urlInspectionCompleted = `${environment.apiUrl}/InspectionCompleted`;
    
    this.changeProgressBar(true);

    const params = new HttpParams()
      .set('InspectionId', inspectionId);
    return this.http.post<InspectionCompletedResponse>(urlInspectionCompleted, '', {params})
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );      
  }

  getNewHeaders(): Observable<LotGroupResponse>{
    const urlLotNumbers = `${environment.apiUrl}/GetNewHeaders`;
    const supplier = SessionData.getCurrentUser().sapVendor;
    this.changeProgressBar(true);

    const params= new HttpParams()
      .set('SupplierNumber', supplier);

    return this.http.get<LotGroupResponse>(urlLotNumbers, {params})
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }

  getInspectionTemplate(inspectionId: string)  {
    const url= `${environment.apiUrl}/InspectionTemplate`; 
    this.changeProgressBar(true);
    const params = new HttpParams()
      .set('InspectionId', inspectionId);
    return this.http.post(url, '', {responseType: 'blob', params}).pipe(
      finalize( () => this.changeProgressBar(false))
    );
  }

  updateNewFalse(groupId: string , materialNumber: string){
      const urlUpdateNewFalse = `${environment.apiUrl}/UpdateNewFalse`;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.changeProgressBar(true);

      const body: UpdateNewFalse = {
        groupId,
        supplierNumber: SessionData.getCurrentUser().sapVendor,
        materialNumber
      };
      return this.http.post<boolean>(urlUpdateNewFalse, body, {headers})
        .pipe(
          switchMap( respuesta => {
            console.log("Respuesta de updateNewFalse: ", respuesta);
            if(respuesta){
              return this.getHeaderCharacOps(groupId, materialNumber);
            }
            else{
              return of(false);
            }
          }),
          finalize(() => this.changeProgressBar(false))
        );
  }
  
  getHeaderCharacOps(groupId: string, materialNumber: string): Observable<HeaderResponse>{
      this.characteristicsNewInsp = [];
      const urlGetHeaderOps = `${environment.apiUrl}/GetHeaderCharacOps`;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      //this.changeProgressBar(true);
    
      const body: HeaderRequest = {
          group : groupId,
          materialNumber: materialNumber
      };
      return this.http.post<HeaderResponse>(urlGetHeaderOps, body, {headers});
  }

  uploadInspectionTemplate(inspectionId: string, templateFile: string) {
    const url= `${environment.apiUrl}/UploadTemplate`; 
    this.changeProgressBar(true);

    const body = {
      inspectionId : inspectionId,
      templateFile: templateFile
    };

    return this.http.post<any>(url, body).pipe(
      finalize( () => this.changeProgressBar(false))
    );
    
  }

  uploadCerficationDocumentation(formData: FormData): Observable<CertificationDocumentation>  {
    const url= `${environment.apiUrl}/InspectionCertificationDocument`; 
    this.changeProgressBar(true);
    return this.http.post<any>(url, formData).pipe(
      finalize( () => this.changeProgressBar(false))
    );
    
  }

  downloadCerfiticationDocuments(inspectionId: number): Observable<any> {
    const url = `${environment.apiUrl}/InspectionCertificationDocument/${inspectionId}`;
    this.changeProgressBar(true);
    return this.http.get(url, { responseType: "blob" })
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }

  deleteCerfiticationDocuments(idDocument: number) {
    const url= `${environment.apiUrl}/InspectionCertificationDocument/${idDocument}`; 
    this.changeProgressBar(true);
    return this.http.delete<string[]>(url)
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }

  checkInspectionIsComplete(inspectionId: string) {
    const url= `${environment.apiUrl}/CheckInspComplete`; 
    this.changeProgressBar(true);

    const params= new HttpParams()
      .set('InspectionId', inspectionId);
    
      console.log(params);

    return this.http.get<string[]>(url, {params})
      .pipe(
        finalize( () => this.changeProgressBar(false))
      );
  }


  approveInspection(isApproved: boolean, inspectionID: string, comments: string) {
    const urlApproveInspection = `${environment.apiUrl}/InspectionApproval`;

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.changeProgressBar(true);

    const body: ApproveInspectionBody = {
      inspectionID,
      comments,
      isApproved
    };
    
    return this.http.post<HeaderResponse>(urlApproveInspection, body).pipe(
      finalize( () => this.changeProgressBar(false))
    );
  }

  downloadActionFromBlob(blob: any, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
