<div class="dialog">
    <div class="dialog_button">
      <h1 mat-dialog-title>{{data.titulo}}</h1>
      <div class="dialog_button-icon" (click)="cerrarDialogo()">
        <mat-icon class="menu_list_icon" aria-label="Close">close</mat-icon>
      </div>
    </div>
    <div mat-dialog-content>
      <p *ngIf="!data.translate" style="white-space: pre-line">{{ data.mensaje }}</p>
      <p *ngIf="data.translate">{{data.mensaje | translate}}</p>
      <iframe *ngIf="data.termsAndConditionsUrl" [src]="data.termsAndConditionsUrl" height="800px" width="100%"></iframe>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button color="primary" (click)="confirmado()" *ngIf="data.showAcceptButton">ACCEPT</button>
      <button mat-flat-button color="accent" (click)="cerrarDialogo()" *ngIf="data.showCloseButton">CLOSE</button>      
      <!-- <button mat-flat-button color="warn" (click)="confirmado()">CANCEL</button> --> <!--Botón rojo por si se necesita-->
    </div>
  </div>
  