import { Injectable } from '@angular/core';
import { MessageBannerServiceService } from './message-banner-service.service';
import { AdminService } from 'src/app/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InspectionService } from 'src/app/services/inspection.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadTemplateService {

  constructor(
    private popUpService: MessageBannerServiceService,
    private adminService: AdminService,
    private inspectionService: InspectionService
  ) { }

  downloadExcelTemplateAdmin(inspectionId: string, title: string) {
    this.adminService.getInspectionReportExcel(inspectionId).subscribe(
      (valor) => {
        if (valor instanceof Blob) {
          let url = window.URL.createObjectURL(valor);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = title;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        else {
          this.popUpService.showMessage('ERROR', valor, false, true, false);
        }
      },
      (error: HttpErrorResponse) => {
        this.popUpService.showMessage('ERROR', error.message, false, true, false);
      });
  }

  downloadExcelTemplateSupplier(inspectionId: string, title: string) {
    this.inspectionService.getInspectionTemplate(inspectionId).subscribe(
      (valor) => {
        if (valor instanceof Blob) {
          let url = window.URL.createObjectURL(valor);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = title;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        else {
          this.popUpService.showMessage('ERROR', valor, false, true, false);
        }
      },
      (error: HttpErrorResponse) => {
        this.popUpService.showMessage('ERROR', error.message, false, true, false);
      });
  }
}
