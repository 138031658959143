import { Injectable } from '@angular/core';
import { GeneralService } from '../shared/services/general.service';
import { Inspeccion } from '../interfaces/inspeccion.interface';
import { environment } from 'src/environments/environment';
import { Observable, finalize, switchMap, of } from 'rxjs';
import { SearchInspectionResponse } from '../interfaces/searchInspectionResponse.interface';
import { Caracteristica } from '../interfaces/caracteristica.interface';
import { PageEvent } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { SupplierListAdmin } from '../interfaces/supplierListAdmin.interface';
import { CertificationDocumentation } from '../interfaces/CertificationDocument.interface';
import { CertificationDocumentationHistory } from '../interfaces/CertificationDocumentHistory.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends GeneralService {
  //Filtros para mantenerlos entre navegaciones
  sapVendor: string = '';
  material: string = '';
  groupID: string = '';
  inspectionID: string = '';

  //Ordenamiento
  orderBy: number = 0;
  orderType: number = 0;

  private _certificationDocumentation: CertificationDocumentation | undefined;
  private _certificationDocumentationHistory: CertificationDocumentationHistory[] = [];

  private _inspections: Inspeccion[] = [];
  private _inspectionSelected: Inspeccion | undefined;

  private _paginatedInspections: Inspeccion[] = [];
  //Datos para paginador
  totalAllInspections: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;

  dataSource = new MatTableDataSource<Inspeccion>(this._paginatedInspections);

  get myDataSource() {
    return this.dataSource;
  }

  get certificationDocumentation() {
    return this._certificationDocumentation;
  }

  get certificationDocumentationHistory() {
    return this._certificationDocumentationHistory;
  }

  get inspections() {
    return [...this._inspections];
  }

  filterDataSource(value: string) {
    const valueSearched = value.trim().toLowerCase();
    const filteredInspections = this._inspections.filter((inspection) => {
      return JSON.stringify(inspection.header)
        .toLowerCase()
        .includes(valueSearched);
    });
    this.dataSource.data = filteredInspections;
  }

  get paginatedInspections() {
    return this._paginatedInspections;
  }

  pageChanged(event: PageEvent) {
    this._paginatedInspections = this.inspections.slice(
      event.pageIndex * event.pageSize,
      event.pageIndex * event.pageSize + event.pageSize
    );
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setDataSource(this._paginatedInspections);
  }

  resetInspections() {
    this._inspections = [];
    this._paginatedInspections = [];
    this._inspectionSelected = undefined;
  }

  setSelectedInspection(inspection: Inspeccion) {
    this._inspectionSelected = inspection;
    this._certificationDocumentation = inspection.certificationDocument;
    this._certificationDocumentationHistory = this.parseDocumentHistory(inspection.certificationDocumentHistory);
    this.setColorInspection();
    console.log(this._inspectionSelected);
  }

  parseDocumentHistory(certificationDocumentHistory: CertificationDocumentationHistory[]) {
    const response: CertificationDocumentationHistory[] = [];
    certificationDocumentHistory.forEach(oneCertification => {
      oneCertification.actionDate = this.formatDateTimeForDocumentHistory(oneCertification.actionDate)
      response.push(oneCertification)
    });
    return response;
  }

  formatDateTimeForDocumentHistory(input: string | Date): string {
    let date: Date;

    if (typeof input === 'string') {
      date = new Date(input);
    } else {
      date = input;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  setColorInspection() {
    this._inspectionSelected!.characteristic.forEach((detailsChar) => {
      if (detailsChar.inspect != detailsChar.inspected) {
        detailsChar.colorRecord = '#ecd3da';
        let numOutOfRank = 0;
        this._inspectionSelected!.inspValue.forEach((oneValue) => {
          if (String(oneValue.value) == String(detailsChar.id)) {
            if (oneValue.status === 'NOT OK') {
              numOutOfRank += 1;
            }
          }
        });
        detailsChar.label = `${detailsChar.inspected} / ${detailsChar.inspect} inspected`;
      } else {
        let numOutOfRank = 0;
        this._inspectionSelected!.inspValue.forEach((oneValue) => {
          if (oneValue.valueIndex == detailsChar.id) {
            if (oneValue.outOfRank) {
              const error = '"error"';
              numOutOfRank += 1;
            }
          }
        });
        detailsChar.label = `${detailsChar.inspected} / ${detailsChar.inspect} inspected`;
        detailsChar.colorRecord = '#e5f4e9';
        detailsChar.numberOutOfRank = numOutOfRank;
      }
    });
  }

  get selectedInspection() {
    return this._inspectionSelected;
  }

  get characteristics(): Caracteristica[] {
    if (this._inspectionSelected) {
      //Establecer los valores de valores para cada cracteristica
      this._inspectionSelected.characteristic.forEach((caracteristica) => {
        const values = this._inspectionSelected?.inspValue.filter((value) => {
          return (
            value.inspectionId === caracteristica.inspectionId &&
            value.charId === caracteristica.charId &&
            caracteristica.id === value.valueIndex
          );
        });

        //Inicializar las tablas a false
        caracteristica.table1 = false;
        caracteristica.table2 = false;
        caracteristica.table3 = false;
        caracteristica.table4 = false;
        caracteristica.table5 = false;
        caracteristica.valuesTable1 = [];
        caracteristica.valuesTable2 = [];
        caracteristica.valuesTable3 = [];
        caracteristica.valuesTable4 = [];
        caracteristica.valuesTable5 = [];
        if (values && values.length > 0) {
          switch (values.length) {
            case 1:
              caracteristica.table1 = true;
              caracteristica.valuesTable1 = values;
              break;
            case 2:
              caracteristica.table1 = true;
              caracteristica.table2 = true;
              caracteristica.valuesTable1?.push(values[0]);
              caracteristica.valuesTable2?.push(values[1]);
              break;
            case 3:
              caracteristica.table1 = true;
              caracteristica.table2 = true;
              caracteristica.table3 = true;
              caracteristica.valuesTable1?.push(values[0]);
              caracteristica.valuesTable2?.push(values[1]);
              caracteristica.valuesTable3?.push(values[2]);
              break;
            case 4:
              caracteristica.table1 = true;
              caracteristica.table2 = true;
              caracteristica.table3 = true;
              caracteristica.table4 = true;
              caracteristica.valuesTable1?.push(values[0]);
              caracteristica.valuesTable2?.push(values[1]);
              caracteristica.valuesTable3?.push(values[2]);
              caracteristica.valuesTable4?.push(values[3]);
              break;
            case 5:
              caracteristica.table1 = true;
              caracteristica.table2 = true;
              caracteristica.table3 = true;
              caracteristica.table4 = true;
              caracteristica.table5 = true;
              caracteristica.valuesTable1?.push(values[0]);
              caracteristica.valuesTable2?.push(values[1]);
              caracteristica.valuesTable3?.push(values[2]);
              caracteristica.valuesTable4?.push(values[3]);
              caracteristica.valuesTable5?.push(values[4]);
              break;
            default:
              //Calcular los valores para cada tabla
              caracteristica.table1 = true;
              caracteristica.table2 = true;
              caracteristica.table3 = true;
              caracteristica.table4 = true;
              caracteristica.table5 = true;

              //Se ordenan por fila
              /*values.forEach((value, index)=> {
                switch(index){
                  case 0:
                    caracteristica.valuesTable1?.push(value);
                    break;
                  case 1:
                    caracteristica.valuesTable2?.push(value);
                    break;
                  case 2:
                    caracteristica.valuesTable3?.push(value);
                    break;
                  case 3:
                    caracteristica.valuesTable4?.push(value);
                    break;
                  case 4:
                    caracteristica.valuesTable5?.push(value);
                    break;
                  default:
                    const m = index%5;
                    switch(m){
                      case 0:
                        caracteristica.valuesTable1?.push(value);
                        break;
                      case 1:
                        caracteristica.valuesTable2?.push(value);
                        break;
                      case 2:
                        caracteristica.valuesTable3?.push(value);
                        break;
                      case 3:
                        caracteristica.valuesTable4?.push(value);
                        break;
                      case 4:
                        caracteristica.valuesTable5?.push(value);
                        break;
                    }
                }
              });*/

              //Se ordenan por tabla, calcular cuantos por tabla
              let valoresTabla1 = 0;
              let valoresOtrasTablas = 0;
              let factor1 = values.length / 5;
              let decimal = factor1 - Math.floor(factor1);
              if (decimal < 0.5) {
                valoresTabla1 = Math.round(factor1) + 1;
                valoresOtrasTablas = Math.round(factor1);
              } else {
                valoresTabla1 = Math.round(factor1);
                valoresOtrasTablas = valoresTabla1;
              }

              //Valores Tabla1
              values.forEach((value, index) => {
                if (index < valoresTabla1) {
                  caracteristica.valuesTable1?.push(value);
                }
              });
              //Valores Tabla2
              values.forEach((value, index) => {
                if (index > valoresTabla1 - 1) {
                  if (index < valoresOtrasTablas * 2) {
                    caracteristica.valuesTable2?.push(value);
                  }
                }
              });
              //Valores tabla 3
              values.forEach((value, index) => {
                if (index > valoresTabla1 * 2 - 1) {
                  if (index < valoresOtrasTablas * 3) {
                    caracteristica.valuesTable3?.push(value);
                  }
                }
              });
              //Valores tabla 4
              values.forEach((value, index) => {
                if (index > valoresTabla1 * 3 - 1) {
                  if (index < valoresOtrasTablas * 4) {
                    caracteristica.valuesTable4?.push(value);
                  }
                }
              });
              //Valores tabla 5
              values.forEach((value, index) => {
                if (index > valoresTabla1 * 4 - 1) {
                  if (index < valoresOtrasTablas * 5) {
                    caracteristica.valuesTable5?.push(value);
                  }
                }
              });
          }
        } else {
          caracteristica.table1 = true;
        }
        //caracteristica.values = values;
      });
      return this._inspectionSelected.characteristic;
    } else {
      return [];
    }
  }

  createSupplier(supplierInfo: SupplierListAdmin) {
    const url= `${environment.apiUrl}/Suppliers`;
    const body = [
      {
        "SupplierNumber": supplierInfo.supplierNumber,
        "Name": supplierInfo.name,
        "IsVisible": supplierInfo.isVisible
      }
    ];
    
    this.changeProgressBar(true);
    return this.http.post(url, body).pipe(
      finalize( () => this.changeProgressBar(false))
    );
  }

  getSupplierList(page?: number) {
    const urlLSuppliersList = `${environment.apiUrl}/Suppliers`;

    this.changeProgressBar(true);

    return this.http.get<any>(urlLSuppliersList)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  getSupplierListHomePageAdmin() {
    const urlLSuppliersList = `${environment.apiUrl}/Suppliers?IsVisible=true`;

    this.changeProgressBar(true);

    return this.http.get<any>(urlLSuppliersList)
      .pipe(
        finalize(() => this.changeProgressBar(false))
      );
  }

  updateSupplierList(data: any) {
    const url= `${environment.apiUrl}/Suppliers/${data.id}`;
    
    const body = {
      id:             data.id,
      supplierNumber: data.supplierNumber,
      name:           data.name,
      isVisible:      data.isVisible 
    };
    
    this.changeProgressBar(true);
    return this.http.put(url, body).pipe(
      finalize( () => this.changeProgressBar(false))
    );
  }
  getInspectionsAdmin(
    sapVendor: string,
    material: string,
    groupID: string,
    inspectionID: string,
    isFirstCall?: boolean
  ): Observable<string> {
    this.sapVendor = sapVendor;
    this.material = material;
    this.groupID = groupID;
    this.inspectionID = inspectionID;

    this.resetInspections();
    const urlSearchInspection = `${environment.apiUrl}/SearchInspection`;
    this.changeProgressBar(true);
    const body = {
      sapVendor,
      material,
      groupID,
      inspectionID,
    };

    return this.http
      .post<SearchInspectionResponse>(urlSearchInspection, body)
      .pipe(
        finalize(() => this.changeProgressBar(false)),
        switchMap((respuesta) => {
          if (!respuesta.operationFlag) {
            return of(respuesta.error);
          }
          this._inspections = [...respuesta.inspectionValues];
          this.totalAllInspections = this._inspections.length;
          this.pageIndex = 0;
          this.pageSize = 5;
          this._paginatedInspections = this._inspections.slice(
            this.pageIndex * this.pageSize,
            this.pageIndex * this.pageSize + this.pageSize
          );
          if (this.orderBy !== 0) {
            this.reorderInspections(this.orderBy, this.orderType);
          }

          if (respuesta.inspectionValues.length === 0 && !isFirstCall) {
            this.setDataSource(this._paginatedInspections);
            return of('NO RECORDS FOUND');
          } else {
            //Calcular color
            this._inspections.forEach((inspection) => {
              //TODO: see if new color or what
              if (!inspection.header.recordComplete) {
                inspection.header.color = 'pending';
                inspection.header.dinamicStatus = 'Pending';
              } else {
                inspection.header.dinamicStatus = 'Complete';
                if (inspection.header.status === 'Approved') {
                  inspection.header.dinamicStatus = 'Approved';
                }
                if (inspection.header.outOfRank) {
                  inspection.header.color = 'out-specification';
                } else {
                  inspection.header.color = 'completed';
                }
              }

              let label = '';
              let colorRecord = '';

              if (
                inspection.characteristic.length != inspection.inspValue.length
              ) {
                label = 'red';
                colorRecord = 'red';
                inspection.characteristic.forEach((detailsChar) => {
                  if (detailsChar.inspect != detailsChar.inspected) {
                    detailsChar.colorRecord = 'red';
                    detailsChar.label = `${detailsChar.inspected} / ${detailsChar.inspect}`;
                    label = 'red';
                    colorRecord = 'red';
                  } else {
                    detailsChar.colorRecord = 'none';
                    detailsChar.label = 'green';
                    label = 'none';
                    colorRecord = 'green';
                  }
                });
              } else if (
                inspection.characteristic.length == inspection.inspValue.length
              ) {
                label = 'none';
                colorRecord = 'green';
              }
              inspection.header.colorRecord = label;
              inspection.header.label = colorRecord;
              inspection.characteristic.forEach((caracteristica) => {
                caracteristica.open = false;
              });
            });
            this.setDataSource(this._paginatedInspections);
            return of('OK');
          }
        })
      );
  }

  getInspectionsAdminCompleted() {
    this._inspections = this._inspections.filter((inspection) => {
      return inspection.header.recordComplete;
    });
    this.totalAllInspections = this._inspections.length;
    this.pageIndex = 0;
    this.pageSize = 10;
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    if (this.orderBy !== 0) {
      this.reorderInspections(this.orderBy, this.orderType);
    }
    this.setDataSource(this._paginatedInspections);
  }

  getInspectionsAdminApproved() {
    this._inspections = this._inspections.filter((inspection) => {
      return inspection.header.status === 'Approved';
    });
    this.totalAllInspections = this._inspections.length;
    this.pageIndex = 0;
    this.pageSize = 10;
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    if (this.orderBy !== 0) {
      this.reorderInspections(this.orderBy, this.orderType);
    }
    this.setDataSource(this._paginatedInspections);
  }

  reorderInspections(orderBy: number, orderType: number) {
    this.orderBy = orderBy;
    this.orderType = orderType;
    switch (orderBy) {
      case 1:
        //ordenar por planta
        this.reorderByPlant(orderType);
        break;
      case 2:
        //ordenar por supplier
        this.reorderBySupplier(orderType);
        break;
      case 3:
        //ordenar por inspection lot
        this.reorderByInspectionLot(orderType);
        break;
      case 4:
        //ordenar por material
        this.reorderByMaterial(orderType);
        break;
      case 5:
        //ordenar por descripcion de material
        this.reorderByDescripcion(orderType);
        break;
      case 6:
        //ordenar por Group ID
        this.reorderByGroup(orderType);
        break;
      case 7:
        //ordenar por Created on
        this.reorderByStart(orderType);
        break;
      case 8:
        //ordenar por Status
        this.reorderByStatus(orderType);
        break;
      default:
        console.log('No ordenar');
    }
  }

  private reorderByPlant(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.plant > b.header.plant) {
          return 1;
        }
        if (a.header.plant < b.header.plant) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.plant > b.header.plant) {
          return -1;
        }
        if (a.header.plant < b.header.plant) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderBySupplier(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.sapvendor > b.header.sapvendor) {
          return 1;
        }
        if (a.header.sapvendor < b.header.sapvendor) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.sapvendor > b.header.sapvendor) {
          return -1;
        }
        if (a.header.sapvendor < b.header.sapvendor) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByInspectionLot(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.lotNumber > b.header.lotNumber) {
          return 1;
        }
        if (a.header.lotNumber < b.header.lotNumber) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.lotNumber > b.header.lotNumber) {
          return -1;
        }
        if (a.header.lotNumber < b.header.lotNumber) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByMaterial(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.material > b.header.material) {
          return 1;
        }
        if (a.header.material < b.header.material) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.material > b.header.material) {
          return -1;
        }
        if (a.header.material < b.header.material) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByDescripcion(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.materialName > b.header.materialName) {
          return 1;
        }
        if (a.header.materialName < b.header.materialName) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.materialName > b.header.materialName) {
          return -1;
        }
        if (a.header.materialName < b.header.materialName) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByGroup(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.groupId > b.header.groupId) {
          return 1;
        }
        if (a.header.groupId < b.header.groupId) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.groupId > b.header.groupId) {
          return -1;
        }
        if (a.header.groupId < b.header.groupId) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByStart(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.createdOn > b.header.createdOn) {
          return 1;
        }
        if (a.header.createdOn < b.header.createdOn) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.createdOn > b.header.createdOn) {
          return -1;
        }
        if (a.header.createdOn < b.header.createdOn) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  private reorderByStatus(orderType: number) {
    if (orderType === 1) {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.dinamicStatus! > b.header.dinamicStatus!) {
          return 1;
        }
        if (a.header.dinamicStatus! < b.header.dinamicStatus!) {
          return -1;
        }
        return 0;
      });
    } else {
      this._inspections = this.inspections.sort(function (a, b) {
        if (a.header.dinamicStatus! > b.header.dinamicStatus!) {
          return -1;
        }
        if (a.header.dinamicStatus! < b.header.dinamicStatus!) {
          return 1;
        }
        return 0;
      });
    }
    this._paginatedInspections = this._inspections.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
    this.setDataSource(this._paginatedInspections);
  }

  /*getInspectionReportExcel(sapVendor: string, groupId: string, lotNumber: string)  {
    const url= `${environment.apiUrl}/GetInspectionReport`; 
    const query = " where Sapvendor like '" + sapVendor + "'" + 
                      " and GroupId like '" + groupId + "'" +
                      " and LotNumber like '" + lotNumber + "'";
    console.log(query);                      
    const body = {
      idUser: SessionData.getCurrentUser().id,
      query: query,
      format: "CSV",
      fileExt: "csv"
    };
    this.changeProgressBar(true);
    return this.http.post(url, body, {responseType: 'blob'}).pipe(
      finalize( () => this.changeProgressBar(false))
    );
  }*/

  getInspectionReportExcel(inspectionId: string) {
    const url = `${environment.apiUrl}/GetInspectionReport`;
    const params = new HttpParams().set('InspectionId', inspectionId);

    this.changeProgressBar(true);
    return this.http
      .post(url, '', { responseType: 'blob', params })
      .pipe(finalize(() => this.changeProgressBar(false)));
  }

  setDataSource(newSource: Inspeccion[]) {
    this.dataSource = new MatTableDataSource(newSource);
  }
}
