import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ProgressBarService } from 'src/app/progress-bar/progress-bar.service';
import { SessionData } from '../localstorage/sessionData';
import { TranslateService } from '@ngx-translate/core';
import { LANG_JSON_MESSAGE } from 'src/assets/i18n/custom-translate';

@Injectable({
  providedIn: 'root',
})

// Clase de la que extienden todos los servicios
export class GeneralService implements OnInit {
  public status = false;
  public headers = new HttpHeaders().set('Content-Type', 'application/json');
  public api: String = '';


  constructor(
    public http: HttpClient,
    public router: Router,
    public progressBarService: ProgressBarService,
    public translate: TranslateService){

      }

  ngOnInit(): void {
    this.progressBarService.obsProgressBarToggle.subscribe(
      (status) => (this.status = status)
    );
  }
  changeProgressBar(value: boolean): void {
    this.progressBarService.toggle(value);
  }

  doPost(
    api: string,
    paramsAction?: any,
    headers = this.headers
  ): Observable<any> {
    this.api = api;
    this.changeProgressBar(true);
    return this.http.post(api, paramsAction, { headers: headers }).pipe(
      map((res) => {
        this.changeProgressBar(false);
        if (res != undefined) {
          (res as any).message = (res as any).typeResponse[
            SessionData.getLanguageResource().toString()
          ];
        }
        return res || {};
      }),
      catchError(this.handleError.bind(this))
    );
  }

  doPut(
    api: string,
    paramsAction?: any,
    headers = this.headers
  ): Observable<any> {
    this.api = api;
    this.changeProgressBar(true);
    return this.http.put(api, paramsAction, { headers: headers }).pipe(
      map((res) => {
        this.changeProgressBar(false);
        if (res != undefined) {
          (res as any).message = (res as any).typeResponse[
            SessionData.getLanguageResource().toString()
          ];
        }
        return res || {};
      }),
      catchError(this.handleError.bind(this))
    );
  }

  doGet(
    api: string,
    headers = this.headers,
    withCredentials?: boolean
  ): Observable<any> {
    this.api = api;
    return this.http
      .get(api, { headers: headers, withCredentials: withCredentials })
      .pipe(
        map((res) => {
          this.changeProgressBar(false);
          if (res != undefined) {
            if ((res as any).message !== undefined) {
              (res as any).message = (res as any).typeResponse[
                SessionData.getLanguageResource().toString()
              ];
            }
          }
          return res || {};
        }),
        catchError(this.handleError)
      );
  }

  doDelete(api: string, headers = this.headers): Observable<any> {
    this.api = api;
    return this.http.delete(api, { headers: headers }).pipe(
      map((res) => {
        this.changeProgressBar(false);
        if (res != undefined) {
          (res as any).message = (res as any).typeResponse[
            SessionData.getLanguageResource().toString()
          ];
        }
        return res || {};
      }),
      catchError(this.handleError)
    );
  }

  // Error
  handleError(error: any) {
    if (this != undefined) {
      if (this.progressBarService !== undefined) {
        this.progressBarService.toggle(false);
      }
      if (error.status == 401 || error.status === 403) {
        if (!this.api.toLocaleLowerCase().includes('signup')) {
          SessionData.doLogout();
          this.router.navigate(['/login']);
        }
      }
    } else {
      if (error.status == 401 || error.status === 403) {
        SessionData.doLogout();
        location.reload();
      }
    }
    return throwError(GeneralService.getMessageErrorStatic(error));
    /*if (this !== undefined) return throwError(GeneralService.getMessageErrorStatic(error));
    else {
      return throwError(GeneralService.getMessageErrorStatic(error));
    }*/
  }



  public static getMessageErrorStatic(error: any): string {
    let msg = '';
    if (error.status == 0 || error.status == 404) {
      if (SessionData.getLanguage().toString() == 'es')
        msg = LANG_JSON_MESSAGE['es']['label-conection-error'];
      else {
        msg = LANG_JSON_MESSAGE['en']['label-conection-error'];
      }
    } else {
      if (
        error.status == 500 ||
        error.status == 204 ||
        error.status == 400 ||
        error.status == 401 ||
        error.status === 403
      ) {
        if (error.error != undefined && error.error.typeResponse != undefined) {
          if (
            error.error.typeResponse.hasOwnProperty(
              SessionData.getLanguageResource().toString()
            )
          ) {
            msg =
              error.error.typeResponse[
                SessionData.getLanguageResource().toString()
              ];
          }
        } else {
          msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
      } else {
        if (error.status == 400) {
          msg = error.error.title;
        } else {
          if (error.status == 0) {
            //alert("Error de conexion");
            if (SessionData.getLanguage().toString() == 'es')
              msg = LANG_JSON_MESSAGE['es']['label-conection-error'];
            else msg = LANG_JSON_MESSAGE['en']['label-conection-error'];
          }
        }
      }
      /*if (error.status != 401) {
        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }*/
    }
    //console.log(error);
    return msg;
  }
}
