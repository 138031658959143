import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionService } from 'src/app/services/inspection.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MessageBannerServiceService } from '../services/message-banner-service.service';

interface InspectionIdUploadCertification {
  inspectionId: string,
}

@Component({
  selector: 'app-upload-certification',
  templateUrl: './upload-certification.component.html',
  styleUrls: ['./upload-certification.component.scss']
})
export class UploadCertificationComponent implements OnInit {

  placeholdertemplate = '';
  miCampo: FormControl = this.fb.control('');
  file: File | null = null;
  selectedFile: any = null;


  constructor(
    public dialogo: MatDialogRef<UploadCertificationComponent>,
    private popUpService: MessageBannerServiceService,
    @Inject(MAT_DIALOG_DATA) public data: InspectionIdUploadCertification,
    private inspectionService: InspectionService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.miCampo.setValue("SELECT A FILE TO UPLOAD");
  }

  upload() {
    if (this.selectedFile === null) {
      this.popUpService.showMessage('ALERT', 'First select a File', false, true, false);
    }
    else {
      const formData = new FormData();
      formData.append('InspectionID', this.data.inspectionId);
      formData.append('File', this.selectedFile, this.selectedFile.name);

      this.inspectionService.uploadCerficationDocumentation(formData)
        .subscribe((valor) => {
          if(valor.id != 0) {
            this.popUpService.showMessage('ALERT', 'File was imported correctly', false, true, false, (resp) => {
              location.reload();  // Reemplaza esto con cualquier lógica que necesites
              console.log('Diálogo cerrado:', resp);
            });
          }
        },
          (error: HttpErrorResponse) => {
            this.popUpService.showMessage('ERROR', 'Error while sending the file: ' + error.error.error, false, true, false);
          });
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] ?? null;
    let selectedFile = (event.target as HTMLInputElement).files?.item(0);
    if (selectedFile) {
      let file: File = event.target.files[0];
      this.miCampo.setValue(file.name);
      this.file = file
    }
  }

  cerrarDialogo(): void {
    this.miCampo.setValue('');
    this.dialogo.close(false);
    this.file = null;
  }
}
