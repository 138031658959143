import { User } from '../dto/user';
import { Plants } from '../dto/plants';
import { Router } from '@angular/router';
import { Token } from '../dto/token';
declare var CONFIG_APP: any;
import './../../../assets/config/config.js';
import { UserData } from 'src/app/interfaces/userInfo.interface';

export class SessionData {

  constructor( public router: Router ) { }

  public static saveToken(token:string,user:any){
    localStorage.setItem('access_token', JSON.stringify(token));
    localStorage.setItem('session_user', JSON.stringify(user));
    //iniciamos el tiempo de sesion
    SessionData.startTimeSession();

  }
  public static updateToken(token:string){
    localStorage.setItem('access_token', JSON.stringify(token));
  }

  public static getToken() {
    const token = localStorage.getItem('access_token') || '';
    return token;
    //let token:Token=<Token>JSON.parse(localStorage.getItem("access_token")||'{}');
    //return token.tokenString;
  }
  public static getSessionUser() {
    let user:User=<User>JSON.parse(localStorage.getItem("session_user")||'{}');
    //siel usuario no esta definido lo tomamos del tocken y lo almacenamos
    if(user.userId==undefined){

    }
    return user;
  }
  public static updateSessionUser(us:User) {
    localStorage.setItem("session_user",JSON.stringify(us));
  }

  public static getSessionPlants():[Plants] {
    let  plants:[Plants] = JSON.parse(localStorage.getItem('plants')||'{}');
    return plants;

  }
  /*public static get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }*/

  public static isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }

  public static doLogout():any {
    let removeToken=localStorage.removeItem('access_token');
    localStorage.removeItem('session_user');
    localStorage.clear();
    return removeToken;
  }

  public static saveLanguage(lang:string) {
    localStorage.setItem("language",lang);
  }

  public static getLanguage():string {
    return  localStorage.getItem('language') || 'en';
  }

  public static getLanguageResource():String {
    let key:string='';
    switch (SessionData.getLanguage()) {
    case 'es':
      key='resourceSpanish';
    break;
    case 'en':
      key='resourceEnglish';
    break;

      default:
        key='resourceSpanish';
        break;
    }
    return key;
  }

  public static startTimeSession(){
    localStorage.setItem("startSessionTime",new Date().toString());
  }

  public static sessionTimeIsOver():boolean{
    if(localStorage.getItem("startSessionTime")!==undefined && localStorage.getItem("startSessionTime")!==null){
      let strDate:string=localStorage.getItem("startSessionTime")||'';
      let dateStart:Date =new Date(Date.parse(strDate));
      dateStart.setSeconds(dateStart.getSeconds() + CONFIG_APP.idleTimeout+CONFIG_APP.idle);

      if(dateStart>new Date()){

        return false;
      }
      return true;
    }
    return true;
  }

  public static saveTokens(token: string, refreshToken: string){
    localStorage.setItem('access_token', token);
    localStorage.setItem('refresh_token', refreshToken);
    //iniciamos el tiempo de sesion
    SessionData.startTimeSession();
  }

  public static updateTokens(token:string, refreshToken: string){
    localStorage.setItem('access_token', token);
    localStorage.setItem('refresh_token', refreshToken);
  }

  public static getCurrentUser(){
    let user : UserData = <UserData> JSON.parse(localStorage.getItem("session_user")||'{}');
    //siel usuario no esta definido lo tomamos del tocken y lo almacenamos
    /*if(user.userId==undefined){

    }*/
    return user;
  }

  public static getRefreshToken(){
    return localStorage.getItem('refresh_token') || '';
  }


}
