import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private progressBarToggle = new BehaviorSubject<boolean>(false);

  /**
    * The custom message to set
    * @var {string} customMessage
    */
   public obsProgressBarToggle = this.progressBarToggle.asObservable();

   /**
    * Change the message between components
    * @function changeMessage
    * @param {string} msg
    * @return {void}
    */
    public toggle(status: boolean): void {
      this.progressBarToggle.next(status);
    }

}
